import React, { useState } from "react";
import { IconType } from "react-icons";
import { FiMail, FiPhone, FiHome, FiCode } from "react-icons/fi";
import { Impressum } from "./impressum";
import { TextReveal } from "./text-reveal";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  const [openImpressum, setOpenImpressum] = useState(false);

  return (
    <div id="contact" className="flex justify-center">
      <div
        className="max-w-[1200px] flex flex-col justify-between h-screen w-full p-2"
        style={{ height: "calc(100vh - 74px )" }}
      >
        <TextReveal />
        <h2 className="text-4xl sm:text-7xl py-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-violet-500 to-[#0092ca] font-[Anton] uppercase">
          {t("contact.header")}
        </h2>
        <div className="grid gap-4 grid-cols-2">
          <Card
            title="Twylo Digital Solutions - FZCO"
            subtitle={t("contact.card1")}
            onClick={() => setOpenImpressum(true)}
            Icon={FiCode}
          />
          <Card
            title={t("contact.card2")}
            subtitle="IFZA Business Park, DDP"
            href="https://maps.app.goo.gl/M4fGpxwmbiw5RamQ7"
            target="_blank"
            Icon={FiHome}
          />
          <Card
            title={t("contact.card3")}
            subtitle="mail@twylo.net"
            href="mailto:mail@twylo.net"
            Icon={FiMail}
          />
          <Card
            title={t("contact.card4")}
            subtitle="+316 17147224"
            href="tel:+31617147224"
            Icon={FiPhone}
          />
        </div>
      </div>
      <Impressum open={openImpressum} setOpen={setOpenImpressum} />
    </div>
  );
};

interface CardType {
  title: string;
  subtitle: string;
  Icon: IconType;
  href?: string;
  target?: string;
  onClick?: () => void;
}

const Card = ({ title, subtitle, Icon, href, target, onClick }: CardType) => {
  return (
    <a
      href={href}
      target={target}
      onClick={onClick ?? undefined}
      className="w-full p-4 rounded border-[1px] border-slate-300 relative overflow-hidden group bg-slate-900"
    >
      <div className="absolute inset-0  bg-gradient-to-r from-violet-500 to-[#0092ca]  translate-y-[100%] group-hover:translate-y-[0%] transition-transform duration-300" />

      <Icon className="absolute z-10 -top-12 -right-12 text-9xl text-slate-100 group-hover:text-slate-800 group-hover:rotate-12 transition-transform duration-300" />
      <Icon className="mb-2 text-2xl text-[#0092ca] group-hover:text-white transition-colors relative z-10 duration-300" />
      <h3 className="font-medium text-lg text-slate-950 group-hover:text-white relative z-10 duration-300">
        {title}
      </h3>
      <p className="text-slate-400 group-hover:text-violet-200 relative z-10 duration-300">
        {subtitle}
      </p>
    </a>
  );
};

export default Contact;
