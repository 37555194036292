export type TeamMember = {
  id: number;
  name: string;
  img: string;
  designation: string;
};

export const teamMembers: TeamMember[] = [
  {
    id: 1,
    name: "Daniel Wulfing",
    img: "/team/Daniel.png",
    designation: "Chief Executive Officer / BackEnd-Developer",
  },
  {
    id: 2,
    name: "Viktor Grünwald",
    img: "/team/Viktor.png",
    designation: "Chief Operating Officer / FrontEnd-Developer",
  },
  {
    id: 3,
    name: "Tony Schroeder",
    img: "/team/Tony.png",
    designation: "FullStack-Developer / UI-UX Designer",
  },
];
