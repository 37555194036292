// Twylo Digital Solu5ons – FZCO
// IFZA Business Park, DDP
// PO Box 342001
// Dubai
// United Arab Emirates

export const impressumData = {
  heading: "Impressum",
  sections: [
    {
      title: "Angaben gemäß § 5 TMG",
      content: [
        {
          subtitle: "<strong>Twylo Digital Solutions - FZCO</strong>",
          value:
            "IFZA Business Park, DDP<br />PO Box 342001<br />Dubai, United Arab Emirates",
        },
      ],
    },
    {
      title: "Kontakt",
      content: [
        {
          subtitle: "Telefon",
          value: "+31 61714 7224",
        },
        {
          subtitle: "E-Mail",
          value: "mail@twylo.net",
        },
      ],
    },
    // {
    //   title: "Uitreksel Handelsregister",
    //   content: [
    //     {
    //       subtitle: "",
    //       value: "Statutaire zetel: gemeente Venlo<br />RSIN: 866658087",
    //     },
    //   ],
    // },
    // {
    //   title: "KvK-Nummer",
    //   content: [
    //     {
    //       subtitle: "",
    //       value: "94158185",
    //     },
    //   ],
    // },
  ],
};
